<template>
  <div>

    <Hall ref="hall" />

  </div>
</template>

<script>

import Hall from '@/components/hall/Hall/Hall.vue'


export default {
  name: "Sell",

  components: {
    Hall
  },

  data() {
    return {

    }
  },

  created() {


    //this.$store.commit('loadEventMapKassa', 436);

    //console.log(this.$store.state.eventMapKassa);

  },

  mounted() {

    this.$refs.hall.prepareHall({

      type:"adm",
      mode:"base",

      view:'',
      options:{},

      layout_id:28,
      layout_pm:33,
      layout_am:34,


    })

  },

  methods(){

  }


}
</script>

<style scoped>

</style>
